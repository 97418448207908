<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require(`@/assets/images/logo${$vuetify.theme.dark ? '' : '--dark'}.png`)"
          max-height="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
    </div>

    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline" />

      <nav-menu-section-title title="LIBRARIES" />

      <nav-menu-link title="Fitness Exercises" :to="{ name: 'fitness-exercises' }" :icon="icons.mdiWeightLifter" />
      <nav-menu-link title="Practice Drills" :to="{ name: 'practice-drills' }" :icon="icons.mdiWeightLifter" />
      <nav-menu-link title="Fitness Routines" :to="{ name: 'fitness-routines' }" :icon="icons.mdiAvTimer" />
      <nav-menu-link title="Practice Sessions" :to="{ name: 'practice-sessions' }" :icon="icons.mdiFileChartOutline" />
      <nav-menu-link title="Fitness Assessments" :to="{ name: 'fitness-assessments' }" :icon="icons.mdiDumbbell" />
      <nav-menu-link title="Golf Skills Assessments" :to="{ name: 'golf-skills-assessments' }" :icon="icons.mdiGolfTee" />
    </v-list>

    <template v-if="hasRole('ADMIN')">
      <nav-menu-section-title title="ORGANIZATION" />
      <v-list expand shaped class="vertical-nav-menu-items pr-5">
        <nav-menu-link title="Academies" :to="{ name: 'academies' }" :icon="icons.mdiOfficeBuilding" />
      </v-list>
    </template>

    <template v-if="hasRole('COACH') && userAcademyId">
      <nav-menu-section-title title="ORGANIZATION" />
      <v-list expand shaped class="vertical-nav-menu-items pr-5">
        <nav-menu-link
          title="My Academy"
          :to="{ name: 'academy-details', params: { id: userAcademyId } }"
          :icon="icons.mdiOfficeBuilding"
        />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiAvTimer,
  mdiDumbbell,
  mdiGolfTee,
  mdiHomeOutline,
  mdiWeightLifter,
  mdiFileChartOutline,
  mdiOfficeBuilding,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import useAuth from '@/composables/useAuth'
import useAcademies from '@/composables/api/academies';
import { ref, onMounted } from 'vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },

  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },

  setup() {
    const { hasRole } = useAuth()
    const { getUserAcademy } = useAcademies()
    const userAcademyId = ref(null)

    onMounted(async () => {
      if (hasRole('COACH')) {
        try {
          const academy = await getUserAcademy()
          userAcademyId.value = academy.companyId || null
        } catch (error) {
          console.error('Error fetching academy for user:', error)
        }
      }
    })

    return {
      hasRole,
      icons: {
        mdiAvTimer,
        mdiDumbbell,
        mdiGolfTee,
        mdiHomeOutline,
        mdiWeightLifter,
        mdiFileChartOutline,
        mdiOfficeBuilding,
      },
      userAcademyId,
    }
  },
}
</script>


<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }

  box-shadow: none;
  border-radius: 0;
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
